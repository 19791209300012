import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from 'axios';

const Login = props => {
    const { setLoggedIn } = props;
    const [user, setUser] = useState("")
    const [password, setPassword] = useState("")
    const [userError, setUserError] = useState("")
    const [passwordError, setPasswordError] = useState("")

    const navigate = useNavigate();

    const onButtonClick = () => {
        setUserError("")
        setPasswordError("")

        // Check if the user has entered both fields correctly
        if ("" === user) {
            setUserError("Please enter your username")
            return
        }

        if ("" === password) {
            setPasswordError("Please enter your password")
            return
        }

        logIn();
    }

    // Log in a user with username and password
    const logIn = () => {
        axios.post("https://dataeye-be.orienteed.com/login", {
            user,
            password
        })
            .then((res) => {
                localStorage.setItem("user", JSON.stringify({ user, token: res.data.token }));
                setLoggedIn(true);
                navigate("/products")
            })
            .catch((err) => {
                alert(err.response.data.message)
            })
    }


    return <div className={"mainContainer"}>
        <div className={"titleContainer"}>
            <div>Login</div>
        </div>
        <br />
        <div className={"inputContainer"}>
            <input
                value={user}
                placeholder="Enter your username here"
                onChange={ev => setUser(ev.target.value)}
                className={"inputBox"} />
            <label className="errorLabel">{userError}</label>
        </div>
        <br />
        <div className={"inputContainer"}>
            <input
                type="password"
                value={password}
                placeholder="Enter your password here"
                onChange={ev => setPassword(ev.target.value)}
                className={"inputBox"} />
            <label className="errorLabel">{passwordError}</label>
        </div>
        <br />
        <div className={"inputContainer"}>
            <input
                className={"inputButton"}
                type="button"
                onClick={onButtonClick}
                value={"Log in"}
            />
            <input
                className={"inputButtonAlter"}
                type="button"
                onClick={() => navigate("/")}
                value={"Return"}
            />
        </div>
    </div>
}

export default Login
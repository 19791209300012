import { useState } from 'react';
import axios from 'axios';
import '../styles/createStyles.css';
import StatusDialog from './statusDialog';

const CreateProductModal = (props) => {
    const { open, close } = props;
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [price, setPrice] = useState("");
    const [visibility, setVisibility] = useState(false);
    const [salePrice, setSalePrice] = useState("");
    const [brand, setBrand] = useState("");
    const [mpn, setMpn] = useState("");
    const [gtin, setGtin] = useState("");
    const [errors, setErrors] = useState([]);
    const [openStatus, setOpenStatus] = useState(false);
    const [statusFields, setStatusFields] = useState({});

    const handleSubmit = () => {
        let currentErrors = [];
        setErrors([]);

        if (!/^\d+.\d{2}/.test(price)) {
            currentErrors = [...currentErrors, { id: "price", message: "Please enter a valid price" }];
            setErrors(currentErrors);
        }

        if (!/^\d+.\d{2}/.test(salePrice)) {
            currentErrors = [...currentErrors, { id: "sale_price", message: "Please enter a valid sale price" }];
            setErrors(currentErrors);
        }

        if (!currentErrors.length) {
            axios.post("https://dataeye-be.orienteed.com/create-product", {
                name: name,
                description: description,
                price: price,
                visibility: visibility,
                sale_price: salePrice,
                brand: brand,
                mpn: mpn,
                gtin: gtin
            }, { headers: { user: JSON.parse(localStorage.getItem("user")).user, token: JSON.parse(localStorage.getItem("user")).token } })
                .then((res) => {
                    setStatusFields({ message: res.data.message, product: res.data.product });
                    setOpenStatus(true);
                })
                .catch((err) => {
                    setStatusFields({ message: err.response.data.message });
                    setOpenStatus(true);
                })
        }
    }

    if (!open) return null
    return (
        <div className="overlay">
            <div className="modal">
                <div className="ui form">
                    <div className="field">
                        <div className='two fields'>
                            <div className="field">
                                <label>Name</label>
                                <input placeholder="Name" type="text" value={name} onChange={ev => setName(ev.target.value)} />
                            </div>
                            <div className="field">
                                <label>Brand</label>
                                <input placeholder="Brand" type="text" value={brand} onChange={ev => setBrand(ev.target.value)} />
                            </div>
                        </div>
                        <div className="field">
                            <label>Description</label>
                            <input placeholder="Description" type="text" value={description} onChange={ev => setDescription(ev.target.value)} />
                        </div>
                        <div className="two fields">
                            <div className={errors.find((err) => err.id === "sale_price") ? "field error" : "field"}>
                                <label>Sale Price</label>
                                <input placeholder="Sale price" type="number" value={salePrice} onChange={ev => setSalePrice(ev.target.value)} />
                                <label className="errorLabel">{errors.find((err) => err.id === "sale_price")?.message}</label>
                            </div>
                            <div className={errors.find((err) => err.id === "price") ? "field error" : "field"}>
                                <label>Price</label>
                                <input placeholder="Price" type="number" value={price} onChange={ev => setPrice(ev.target.value)} />
                                <label className="errorLabel">{errors.find((err) => err.id === "price")?.message}</label>
                            </div>
                        </div>
                        <div className="two fields">
                            <div className="field">
                                <label>MPN</label>
                                <input placeholder="mpn" type="number" value={mpn} onChange={ev => setMpn(ev.target.value)} />
                            </div>
                            <div className="field">
                                <label>GTIN</label>
                                <input placeholder="gtin" type="number" value={gtin} onChange={ev => setGtin(ev.target.value)} />
                            </div>
                        </div>
                        <div className="field">
                            <div className="ui checkbox">
                                <input type="checkbox" tabIndex="0" defaultChecked={visibility} onChange={ev => setVisibility(ev.target.checked)} />
                                <label>Visible on storefront</label>
                            </div>
                        </div>
                    </div>
                    <div>
                        <button className="ui right floated button" onClick={close}>Cancel</button>
                        <button className="ui right floated primary button" onClick={() => handleSubmit()}>Save</button>
                    </div>
                </div>
                {openStatus ? <StatusDialog message={statusFields?.message} close={() => {setOpenStatus(false); close()}} redirect={"/products"} product={statusFields.product} /> : null}
            </div>
        </div>
    )
}

export default CreateProductModal;
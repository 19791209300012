import { useNavigate } from "react-router-dom";

const StatusDialog = (props) => {
    const { message, close, redirect, product } = props;
    const navigate = useNavigate();

    const onClose = (dir) => {
        close();
        navigate(dir);
        window.location.reload(false)
    }

    return (
        <div className="overlay">
            <div className="modal" style={{ al: "center" }}>
                <h3 style={{ textAlign: "center", }}>{message}</h3>
                <div className="ui grid center aligned" style={{paddingTop: "1rem"}}>
                    <button className="ui button" onClick={() => onClose(redirect)}>Ok</button>
                    {product ? <button className="ui primary button" onClick={() => onClose(`/variants/${product}`)}>Go to product</button> : null}
                </div>
            </div>
        </div>)
};

export default StatusDialog;
import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import logo from "../images/data-eye-logo.png";
import axios from 'axios';

const Header = (props) => {
    const { productFetch, setProductList, setTotalPages, isVariant, setLoggedIn } = props;
    const [searchTerm, setSearchTerm] = useState('');
    const [displayFilter, setDisplayFilter] = useState(-1);
    const [flagFilter, setFlagFilter] = useState(-1);
    const [productFilter, setProductFilter] = useState(-1);
    const navigate = useNavigate();

    const onLogoutClick = () => {
        axios.post("https://dataeye-be.orienteed.com/logout", {}, { headers: { user: JSON.parse(localStorage.getItem("user")).user, token: JSON.parse(localStorage.getItem("user")).token } })
        localStorage.removeItem("user")
        setLoggedIn(false);
        navigate("/");
    }

    useEffect(() => {
        let result = [...productFetch];

        if (productFetch.length != 0) {
            if (searchTerm != '') {
                let search = searchTerm.toLowerCase();
                result = isVariant ? result.filter(p => p.description?.toLowerCase().includes(search) || p.sku?.toLowerCase().includes(search)) : result.filter(p => p.description?.toLowerCase().includes(search) || p.brand?.toLowerCase().includes(search))
            }

            if (displayFilter != -1) {
                result = result.filter(p => displayFilter == 0 ? !!p.display_on_website : !p.display_on_website)
            }

            if (flagFilter != -1) {
                result = result.filter(p => p.flag == flagFilter)
            }

            if (productFilter != -1 && isVariant) {
                result = result.filter(p => productFilter == 0 ? p.product_id != 0 : p.product_id == 0)
            }

            setTotalPages(Math.ceil(result.length / 25))

            setProductList(result);

        }
    }, [searchTerm, displayFilter, flagFilter, productFilter])

    return (
        <div className="ui top attached header">
            <div className='ui top attached grid center aligned' style={{paddingBottom: "1rem", paddingTop: "1rem"}}>
            <div style={{ fontSize: 'xx-large', display: "flex", alignItems: "center", paddingRight: "3rem" }}>
                <img src={logo} width="40px" />
                &ensp;Data Eye
            </div>
                <div className="ui input">
                    <input type="text" placeholder="Search..." value={searchTerm} onChange={(ev) => setSearchTerm(ev.target.value)} />
                </div>
                <div className="text">Display&ensp;
                    <select className="ui dropdown" value={displayFilter} onChange={(ev) => setDisplayFilter(ev.target.value)}>
                        <option value={-1}>---</option>
                        <option value={0}>Yes</option>
                        <option value={1}>No</option>
                    </select>
                </div>
                <div className="text">Status&ensp;
                    <select className="ui dropdown" value={flagFilter} onChange={(ev) => setFlagFilter(ev.target.value)}>
                        <option value={-1}>---</option>
                        <option value={0}>Issue</option>
                        <option value={1}>Not in sync</option>
                        <option value={2}>Sync</option>
                    </select>
                </div>
                {isVariant ? <div className="text">Parent product&ensp;
                    <select className="ui dropdown" value={productFilter} onChange={(ev) => setProductFilter(ev.target.value)}>
                        <option value={-1}>---</option>
                        <option value={0}>Yes</option>
                        <option value={1}>No</option>
                    </select>
                </div> : null}
                <button className="ui right floated inverted red button" style={{ marginLeft: "50px" }} onClick={() => onLogoutClick()}>Logout</button>
            </div>
        </div>
    )
};

export default Header;
import { useState, useEffect } from 'react';
import logo from '../images/logo_positivo.svg';

const Footer = (props) => {

    return (
        <div className="ui grid center aligned footer" style={{paddingTop: "1.5rem"}}>
            <a style={{display: "flex", alignItems: "center", fontSize: "larger"}}>Powered by</a>
            <a href="https://support.orienteed.com" target="_blank">
                <img src={logo} width="125px" />
            </a>
        </div>
    )
};

export default Footer;
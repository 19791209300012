// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  color: #333;
}

* {
  box-sizing: border-box;
}

main {
  padding: 2rem 0;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

code {
  background: #f5f5f5;
  border-radius: 4px;
  padding: 0.5rem;
  font-family: 'Courier New', Courier, monospace;
}

.inputButton {
  border: none;
  background: #0069d9;
  color: #fff;
  padding: 12px 24px;
  margin: 8px;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.logout {
  background: #d01919;
}

.inputButton.logout:hover {
  background: #b21e1e;
  
}

.inputButton:hover {
  background: #0056b3;
}

.inputButtonAlter {
  border-color: #acacac;
  background: #fff;
  color: #000000;
  padding: 12px 24px;
  margin: 8px;
  font-size: 16px;
  border-radius: 4px;
  border-width: 1px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.inputButtonAlter:hover {
  background: #dadada;
}
`, "",{"version":3,"sources":["webpack://./src/styles/index.css"],"names":[],"mappings":"AAAA;;EAEE,UAAU;EACV,SAAS;EACT,qEAAqE;EACrE,WAAW;AACb;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,eAAe;EACf,SAAO;EACP,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,kBAAkB;EAClB,eAAe;EACf,8CAA8C;AAChD;;AAEA;EACE,YAAY;EACZ,mBAAmB;EACnB,WAAW;EACX,kBAAkB;EAClB,WAAW;EACX,eAAe;EACf,kBAAkB;EAClB,eAAe;EACf,gCAAgC;AAClC;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;;AAErB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;EACrB,gBAAgB;EAChB,cAAc;EACd,kBAAkB;EAClB,WAAW;EACX,eAAe;EACf,kBAAkB;EAClB,iBAAiB;EACjB,eAAe;EACf,gCAAgC;AAClC;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":["html,\nbody {\n  padding: 0;\n  margin: 0;\n  font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;\n  color: #333;\n}\n\n* {\n  box-sizing: border-box;\n}\n\nmain {\n  padding: 2rem 0;\n  flex: 1;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n}\n\ncode {\n  background: #f5f5f5;\n  border-radius: 4px;\n  padding: 0.5rem;\n  font-family: 'Courier New', Courier, monospace;\n}\n\n.inputButton {\n  border: none;\n  background: #0069d9;\n  color: #fff;\n  padding: 12px 24px;\n  margin: 8px;\n  font-size: 16px;\n  border-radius: 4px;\n  cursor: pointer;\n  transition: background 0.3s ease;\n}\n\n.logout {\n  background: #d01919;\n}\n\n.inputButton.logout:hover {\n  background: #b21e1e;\n  \n}\n\n.inputButton:hover {\n  background: #0056b3;\n}\n\n.inputButtonAlter {\n  border-color: #acacac;\n  background: #fff;\n  color: #000000;\n  padding: 12px 24px;\n  margin: 8px;\n  font-size: 16px;\n  border-radius: 4px;\n  border-width: 1px;\n  cursor: pointer;\n  transition: background 0.3s ease;\n}\n\n.inputButtonAlter:hover {\n  background: #dadada;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

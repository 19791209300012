const CreateStandaloneProduct = (props) => {
    const { setStandalone, close, handleCreatePProduct } = props;

    const onClose = (isStandalone) => {
        setStandalone(isStandalone);
        close();
        handleCreatePProduct(isStandalone)
    }

    return (
        <div className="overlay">
            <div className="modal" style={{ al: "center" }}>
                <h3 style={{ textAlign: "center", }}>Create standalone product?</h3>
                <div className="ui grid center aligned" style={{paddingTop: "1rem"}}>
                    <button className="ui button" onClick={() => onClose(false)}>No</button>
                    <button className="ui button" onClick={() => onClose(true)}>Yes</button>
                </div>
            </div>
        </div>)
};

export default CreateStandaloneProduct;
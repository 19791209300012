import { useNavigate } from "react-router-dom";
import axios from 'axios';

const Home = props => {
    const { loggedIn } = props
    const navigate = useNavigate();

    const onButtonClick = () => {
        if (loggedIn) {
            axios.post("https://dataeye-be.orienteed.com/logout", {}, { headers: { user: JSON.parse(localStorage.getItem("user")).user, token: JSON.parse(localStorage.getItem("user")).token } })
            localStorage.removeItem("user")
            props.setLoggedIn(false)
        } else {
            navigate("/login")
        }
    }

    return <div className="mainContainer">
        <div className={"titleContainer"}>
            <div>Welcome!</div>
        </div>
        <div>
            {loggedIn ? <input
                className={"inputButton"}
                type="button"
                onClick={() => navigate("/products")}
                value="Go to products"
            /> : null}
            <input
                className={loggedIn ? "inputButton logout" : "inputButton"}
                type="button"
                onClick={onButtonClick}
                value={loggedIn ? "Log out" : "Log in"}
            />
        </div>


    </div>
}

export default Home
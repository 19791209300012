import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import EditProductModal from './editProductModal';
import Header from './header';
import CreateProductModal from './createProductModal';
import redCircle from '../images/red-circle.png';
import yellowCircle from '../images/yellow-circle.png';
import greenCircle from '../images/green-circle.png';

const Products = props => {
    const { loggedIn, setLoggedIn } = props;
    const [productFetch, setProductFetch] = useState([]);
    const [productList, setProductList] = useState([]);
    const [productListView, setProductListView] = useState(null);
    const [product, setProduct] = useState(null);
    const [editIsOpen, setEditIsOpen] = useState(false);
    const [createIsOpen, setCreateIsOpen] = useState(false);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const navigate = useNavigate();

    const fetchProducts = async () => {
        let result = [];
        let fileredList = [];

        const { data: { rows: list } } = await axios.get("https://dataeye-be.orienteed.com/products", { headers: { user: JSON.parse(localStorage.getItem("user")).user, token: JSON.parse(localStorage.getItem("user")).token } })
        fileredList = list.filter((p) => p.id !== 0);

        setTotalPages(Math.ceil(fileredList.length / 25))

        setProductFetch(fileredList);
        setProductList(fileredList);

        fileredList.slice(0, 25).map((prod, index) => {
            result.push(
                <tr onClick={() => { setEditIsOpen(true); setProduct(prod) }}>
                    <td>{index + 1}</td>
                    <td>{prod.name}</td>
                    <td>{prod.description?.length > 25 ? prod.description.substring(0, 24) + "..." : prod.description}</td>
                    <td>{prod.brand}</td>
                    <td>{prod.display_on_website ? 'Yes' : 'No'}</td>
                    <td>{prod.price}</td>
                    <td>{prod.sale_price}</td>
                    {prod.flag === 0 ? <td className="negative"><img src={redCircle} width="25px" /></td> : prod.flag === 1 ? <td className="warning"><img src={yellowCircle} width="25px" /></td> : <td className="positive"><img src={greenCircle} width="25px" /></td>}
                    {!prod.is_standalone && <td><button className="ui right labeled icon button" onClick={() => navigate(`/products/${prod.id}`)}>
                        <i className="right arrow icon"></i>
                        Go to variants
                    </button></td>}
                </tr>
            )
        });

        setProductListView(result);
    }

    const getProductList = (page) => {
        let result = [];

        if (page > 0 && page <= totalPages && productList.length) {
            productList.slice((page - 1) * 25, page * 25).map((prod, index) => {
                result.push(
                    <tr onClick={() => { setEditIsOpen(true); setProduct(prod) }}>
                        <td>{index + 1}</td>
                        <td>{prod.name}</td>
                        <td>{prod.description?.length > 25 ? prod.description.substring(0, 24) + "..." : prod.description}</td>
                        <td>{prod.brand}</td>
                        <td>{prod.display_on_website ? 'Yes' : 'No'}</td>
                        <td>{prod.price}</td>
                        <td>{prod.sale_price}</td>
                        {prod.flag === 0 ? <td className="negative"><img src={redCircle} width="25px" /></td> : prod.flag === 1 ? <td className="warning"><img src={yellowCircle} width="25px" /></td> : <td className="positive"><img src={greenCircle} width="25px" /></td>}
                        {!prod.is_standalone && <td><button className="ui right labeled icon button" onClick={() => navigate(`/variants/${prod.id}`)}>
                            <i className="right arrow icon"></i>
                            Go to variants
                        </button></td>}
                    </tr>
                )
            });

            setCurrentPage(page);

            setProductListView(result);
        } else {
            setProductListView(<tr><td colSpan="9"><b>There are no products</b></td></tr>)
        }
    }

    const getPages = useCallback(() => {
        let result = [];

        if (totalPages > 5 && currentPage >= 4 && currentPage <= totalPages - 3 && totalPages != 0) {
            result.push(
                <a className="item" onClick={() => getProductList(1)}>1</a>,
                <a className="item" onClick={() => getProductList(2)}>2</a>,
                <a className="item">...</a>,
                <a className="item" onClick={() => getProductList(currentPage - 1)}>{currentPage - 1}</a>,
                <a className="item active" onClick={() => getProductList(currentPage)}>{currentPage}</a>,
                <a className="item" onClick={() => getProductList(currentPage + 1)}>{currentPage + 1}</a>,
                <a className="item">...</a>,
                <a className="item" onClick={() => getProductList(totalPages - 1)}>{totalPages - 1}</a>,
                <a className="item" onClick={() => getProductList(totalPages)}>{totalPages}</a>
            )
        } else if ((currentPage <= 4 || currentPage >= totalPages - 3) && totalPages >= 7 && totalPages != 0) {
            result.push(<a className={currentPage == 1 ? "item active" : "item"} onClick={() => getProductList(1)}>1</a>,
                <a className={currentPage == 2 ? "item active" : "item"} onClick={() => getProductList(2)}>2</a>,
                <a className={currentPage == 3 ? "item active" : "item"} onClick={() => getProductList(3)}>3</a>,
                <a className={currentPage == 4 ? "item active" : "item"} onClick={() => getProductList(4)}>4</a>,
                <a className="item">...</a>,
                <a className={currentPage === totalPages - 3 ? "item active" : "item"} onClick={() => getProductList(totalPages - 3)}>{totalPages - 3}</a>,
                <a className={currentPage === totalPages - 2 ? "item active" : "item"} onClick={() => getProductList(totalPages - 2)}>{totalPages - 2}</a>,
                <a className={currentPage == totalPages - 1 ? "item active" : "item"} onClick={() => getProductList(totalPages - 1)}>{totalPages - 1}</a>,
                <a className={currentPage == totalPages ? "item active" : "item"} onClick={() => getProductList(totalPages)}>{totalPages}</a>
            )
        } else {
            for (let i = 0; i < totalPages; i++) {
                result.push(<a className={currentPage == i + 1 ? "item active" : "item"} onClick={() => getProductList(i + 1)}>{i + 1}</a>)
            }
        }

        return result;
    }, [totalPages, currentPage]);

    useEffect(() => {
        if (loggedIn) fetchProducts();
    }, [loggedIn])

    useEffect(() => {
        if (productFetch.length && loggedIn) {
            getProductList(1);
        }
    }, [productList, loggedIn])

    if (!loggedIn) {
        return (
            <div className="mainContainer">
                <div className="titleContainer">
                    <div>You must be logged in</div>
                </div>
            </div>
        )
    }
    return (
        //<div className="mainContainer">
        <div>
            <Header productFetch={productFetch} setProductList={setProductList} setTotalPages={setTotalPages} getProductList={getProductList} setLoggedIn={setLoggedIn} isVariant={false} />
            <div className="ui top attached tabular menu">
                <a className="item active" data-tab="products">Products</a>
                <a className="item" data-tab="variants" onClick={() => navigate('/variants')}>Variants</a>
            </div>
            <EditProductModal open={editIsOpen} close={() => setEditIsOpen(false)} product={product} isVariant={false} />
            <CreateProductModal open={createIsOpen} close={() => setCreateIsOpen(false)} />
            <table className="ui selectable celled table">
                <thead>
                    <tr>
                        <th></th>
                        <th>Name</th>
                        <th>Description</th>
                        <th>Brand</th>
                        <th>Display on website</th>
                        <th>Price</th>
                        <th>Sale price</th>
                        <th>Status</th>
                        <th>Variants</th>
                    </tr>
                </thead>
                <tbody>
                    {productListView}
                    <tr>
                        <td colSpan="9" onClick={() => { setCreateIsOpen(true) }}>Add a product...</td>
                    </tr>
                </tbody>
                <tfoot>
                    {productFetch.length != 0 ? <tr><th colSpan="9">
                        <div className="ui right floated pagination menu">
                            {totalPages > 1 ? <a className="icon item" onClick={() => getProductList(currentPage - 1)}>
                                <i className="left chevron icon"></i>
                            </a> : null}
                            {getPages()}
                            {totalPages > 1 ? <a className="icon item" onClick={() => getProductList(currentPage + 1)}>
                                <i className="right chevron icon"></i>
                            </a> : null}
                        </div>
                    </th></tr> : null}
                </tfoot>
            </table>

        </div>
    )
}

export default Products;
import { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import axios from 'axios';
import Home from './components/home';
import Login from './components/login';
import Products from './components/products';
import Variant from './components/variant';
import Variants from './components/variants';
import Footer from './components/footer';
import './styles/App.css';

function App() {
  const [loggedIn, setLoggedIn] = useState(false)

  useEffect(() => {
    // Fetch the user email and token from local storage
    const user = JSON.parse(localStorage.getItem("user"))

    // If the token/email does not exist, mark the user as logged out
    if (!user || !user.token || !user.user) {
      setLoggedIn(false)
      return
    };
    // If the token exists, verify it with the auth server to see if it is valid
    axios.post("https://dataeye-be.orienteed.com/verify", {}, { headers: { user: user.user, token: user.token } })
      .then(r => {
        setLoggedIn('success' === r.data.message)
      })
      .catch(err => {
        localStorage.removeItem("user")
        setLoggedIn(false)
      })
  }, [])

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home loggedIn={loggedIn} setLoggedIn={setLoggedIn} />} />
          <Route path="/login" element={<Login setLoggedIn={setLoggedIn} />} />
          <Route path="/products" element={<Products setLoggedIn={setLoggedIn} loggedIn={loggedIn} />} />
          <Route path="/variants/:productId" element={<Variant setLoggedIn={setLoggedIn} loggedIn={loggedIn} />} />
          <Route path="/variants" element={<Variants setLoggedIn={setLoggedIn} loggedIn={loggedIn} />} />
        </Routes>
      </BrowserRouter>
      <Footer />
    </div>
  );
}

export default App;
